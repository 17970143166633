import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Input, message, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAll } from '../features/statistic/statisticSlice';
import { acceptWithdraw, rejectWithdraw } from '../features/user/userSlice';
import { formatMoney } from '../utils';
import { TRANSACTION_STATUS } from '../utils/constants';
import { getAllUser } from '../features/user/userSlice';

const WithdrawPage = () => {
  const dispatch = useDispatch();
  const [mergedArray, setMergedArray] = useState([]);
  const statistic = useSelector((state) => state.statistic.data);
  const [data, setData] = useState(statistic?.WITHDRAW_LIST);
  const [dataShow, setDataShow] = useState(data);
  const userState = useSelector((state) => state.user.data);
  const [user, setUser] = useState(userState);

  const getAllStatistic = async () => {
    try {
      const data = await dispatch(getAll()).then(unwrapResult);
      setData(data?.WITHDRAW_LIST);
    } catch (error) {
      message.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const data = await dispatch(getAllUser()).then(unwrapResult);
      setUser(data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getAllStatistic();
    fetchUser();
    if (user && dataShow) {
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    }
  }, []);

  const onHandleAcceptWithdraw = async (id) => {
    try {
      await dispatch(acceptWithdraw({ id })).then(unwrapResult);
      getAllStatistic();
      message.success('Xác nhận thành công');
    } catch (error) {
      message.error('Xác nhận thất bại');
    }
  };

  const onHandleRejectWithdraw = async (id) => {
    try {
      await dispatch(rejectWithdraw({ id })).then(unwrapResult);
      getAllStatistic();
      message.success('Từ chối thành công');
    } catch (error) {
      message.error('Từ chối thất bại');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'ref',
      key: 'ref',
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => {
        return <Tag color='blue'>{formatMoney(text)}</Tag>;
      },
      onFilter: (value, record) => record.amount.indexOf(value) === 0,
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return moment(text).format('HH:mm DD/MM/YYYY');
      },
      onFilter: (value, record) => record.createdAt.indexOf(value) === 0,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        if (text === TRANSACTION_STATUS.PENDING) {
          return <Tag color='orange'>Đang chờ</Tag>;
        } else if (text === TRANSACTION_STATUS.SUCCESS) {
          return <Tag color='green'>Đã duyệt</Tag>;
        } else if (text === TRANSACTION_STATUS.CANCEL) {
          return <Tag color='red'>Đã hủy</Tag>;
        }
      },
      onFilter: (value, record) => record.status === value,
      filters: [
        {
          text: 'Đang chờ duyệt',
          value: TRANSACTION_STATUS.PENDING,
        },
        {
          text: 'Đã duyệt',
          value: TRANSACTION_STATUS.SUCCESS,
        },
        {
          text: 'Đã hủy',
          value: TRANSACTION_STATUS.CANCEL,
        },
      ],
    },

    {
      title: 'Thao tác',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            disabled={record.status !== TRANSACTION_STATUS.PENDING}
            type='primary'
            onClick={() => {
              onHandleAcceptWithdraw(record.id);
            }}
          >
            Chấp nhận
          </Button>
          <Button
            disabled={record.status !== TRANSACTION_STATUS.PENDING}
            danger
            type='primary'
            onClick={() => onHandleRejectWithdraw(record.id)}
          >
            Từ chối
          </Button>
        </Space>
      ),
    },
  ];

  const onHandleSearch = (value) => {
    console.log(value);
    if (!value) {
      //setDataShow(data);
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    } else {
      const newData = mergedArray.filter((item) => {
        return item.username.toLowerCase().includes(value.toLowerCase());
      });
      //setDataShow(newData);
      setMergedArray(newData);
    }
  };

  const onHandleSearchSection = (value) => {
    if (!value) {
      //setDataShow(data);
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    } else {
      const newData = mergedArray.filter((item) => {
        return item.betSection.toString() === value.toString();
      });
      //setDataShow(newData);
      setMergedArray(newData);
    }
  };

  return (
    <div className='h-screen'>
      <div className='flex py-2'>
        <Input allowClear placeholder='Tìm kiếm bằng username' onChange={(e) => onHandleSearch(e.target.value)} />
      </div>
      <Table rowKey={(e) => e.id} columns={columns} dataSource={mergedArray} />
    </div>
  );
};

export default WithdrawPage;
