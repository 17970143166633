import { unwrapResult } from '@reduxjs/toolkit';
import { Input, message, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAll } from '../features/statistic/statisticSlice';
import { formatMoney } from '../utils';
import { getAllUser } from '../features/user/userSlice';
const BetPage = () => {
  const dispatch = useDispatch();
  const [mergedArray, setMergedArray] = useState([]);
  const statistic = useSelector((state) => state.statistic.data);
  const [data, setData] = useState(statistic?.BET_LIST);
  const [dataShow, setDataShow] = useState(data);
  const userState = useSelector((state) => state.user.data);
  const [user, setUser] = useState(userState);
  
  const getAllStatistic = async () => {
    try {
      const data = await dispatch(getAll()).then(unwrapResult);
      setData(data?.BET_LIST);
    } catch (error) {
      message.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const data = await dispatch(getAllUser()).then(unwrapResult);
      setUser(data);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getAllStatistic();
    fetchUser();
    if (user && dataShow) {
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    }
  }, []);

  const columns = [
    {
      title: 'ID',
      dataIndex: '',
      key: '',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'ref',
      key: 'ref',
    },
    {
      title: 'Kỳ chơi',
      dataIndex: 'betSection',
      key: 'betSection',
    },
    {
      title: 'Lựa chọn',
      dataIndex: 'betType',
      key: 'betType',
      render: (text) => {
        return text === 'BUY' ? (
          <Tag color='green'>Tăng</Tag>
        ) : text === 'SELL' ? (
          <Tag color='red'>Giảm</Tag>
        ) : text === 'SELL' ? (
          <Tag color='orange'>Chẵn</Tag>
        ) : (
          <Tag color='orange'>Lẻ</Tag>
        );
      },
    },

    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => {
        return <Tag color='blue'>{formatMoney(text)}</Tag>;
      },
      onFilter: (value, record) => record.amount.indexOf(value) === 0,
      sorter: (a, b) => a.amount - b.amount,
    },

    {
      title: 'Kết quả',
      dataIndex: 'betResult',
      key: 'betResult',
      render: (text) => {
        return text === 'WIN' ? <Tag color='green'>THẮNG</Tag> : <Tag color='red'>THUA</Tag>;
      },
    },

    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => {
        return moment(text).format('HH:mm DD/MM/YYYY');
      },
      onFilter: (value, record) => record.createdAt.indexOf(value) === 0,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];

  const onHandleSearch = (value) => {
    if (!value) {
      //setDataShow(data);
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    } else {
      const newData = mergedArray.filter((item) => {
        return item.username.toLowerCase().includes(value.toLowerCase());
      });
      //setDataShow(newData);
      setMergedArray(newData);
    }
  };

  const onHandleSearchSection = (value) => {
    if (!value) {
      //setDataShow(data);
      const mergedData = dataShow.map(item => {
        const matchingRef = user.find(refItem => refItem.username === item.username);
        return {
          ...item,
          ref: matchingRef ? matchingRef.ref : null,
        };
      });
      setMergedArray(mergedData);
    } else {
      const newData = mergedArray.filter((item) => {
        return item.betSection.toString() === value.toString();
      });
      //setDataShow(newData);
      setMergedArray(newData);
    }
  };

  return (
    <div className='h-screen'>
      <div className='flex py-2 justify-between'>
        <div className='w-full'>
          <Input allowClear placeholder='Tìm kiếm bằng username' onChange={(e) => onHandleSearch(e.target.value)} />
        </div>
        <div className='w-full'>
          <Input
            allowClear
            placeholder='Tìm kiếm bằng kỳ chơi'
            onChange={(e) => onHandleSearchSection(e.target.value)}
          />
        </div>
      </div>
      <Table rowKey={(e) => e.id} columns={columns} dataSource={mergedArray} />
    </div>
  );
};

export default BetPage;
